import React, {useLayoutEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Divider,
    Header,
    Grid,
    Label,
    Popup,
    Button,
    Modal,
    Rating,
    Icon, Responsive, Input, List, Checkbox, Pagination, Segment, Dropdown, Table
} from 'semantic-ui-react';
import Moment from 'moment-timezone';
import {authenticate} from "../../actions/auth";
import {
    deviceAdd,
    devicesGet,
    lastWakeupsGet,
    deviceUpdate,
    deviceRemove,
    deviceGroupUpdate,
    deviceSetTamper,
    deviceSetOperMode,
    clearDeviceErrors,
    deviceTagsUpdate,
    wakeupDaysUpdate,
    clearDeviceAlert,
    deviceSetFavourite,
    filterCount, deviceSelectAll, deviceRecovery, deviceClearRecovery,
} from "../../actions/devices";
import {tagsGet, tagAdd, tagRemove, tagCount} from "../../actions/tags";
import {groupCreate, groupsGet, setPersistedGroups} from "../../actions/groups";
import {resetActioned, setActioned} from '../../actions/ui';
import {getLocaleString} from "../../languages/localised";
import AddDevice from '../add-device';
import ItemsList from '../items-list';
import BaseModal from '../base-modal';
import EmptyBoard from '../empty-board';
import WakeupTimes from '../wakeup-times';
import AddToGroup from "../add-to-group";
import EditState from "../edit-state";
import {isHibernateTimezone, simplifyId} from "../../utils/ui";
import RemoveDevice from "../remove-device";
import InstallationHistory from "../installation-history";
import ScrollingModal from "../scrolling-modal";
import DeviceNotes from "../device-notes/device-notes";
import StatusLabel from "../status-label";
import TickList from "../tick-list";
import styled from 'styled-components';
import {motion, AnimatePresence, Transition} from 'framer-motion';
import './manage-devices.css'
import {alertGet} from "../../actions/alerts";
import moment from "moment";
import {hasAccess, isProtected} from "../../utils/protected-component/protected-component";
import ProtectedComponent from "../../utils/protected-component";
import {Link, Redirect} from "react-router-dom";
import OperatingMode from "../operating-mode";
import Countdown from "../countdown";
import DeviceDetailsModal from "../device-details-modal";
import Config from "../../config";
import ReportStolen from "../report-stolen";

const arcEnabled = Config.arc;

const WeekDay = styled.div`
  cursor: pointer;
  margin: 2.2px;    border: 1px grey solid;
    border-radius: 50%;
padding: 0.9px;
    width: 16px;
    height: 16px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
  :hover {
    background-color: rgb(154, 90, 149);border: solid 1px rgb(154, 90, 149);
    box-shadow: inset -2px 2px -2px -2px rgba(0,0,0,0.25);
      cursor: pointer;color: white;font-weight: bold;
  }
`;

const InstalledSnippetComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  padding-bottom: 5px;
`;

const InstalledSnippet = ({title, content}) => (
    <InstalledSnippetComponent>
        <div style={{fontWeight: '500', color: 'rgb(65, 65, 65)', fontSize: '0.9em'}}>{title}</div>
        <div style={{fontWeight: '400', color: 'rgb(187, 187, 187)', fontSize: '0.9em'}}>{content || <span>&nbsp;</span>}</div>
    </InstalledSnippetComponent>
);

const InstalledSnippetMobileComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
`;

const WeekDaySelected = styled.div`
  cursor: pointer;
  margin: 2.2px;    background-color: rgb(154, 90, 149);border: solid 1px rgb(154, 90, 149);
    border-radius: 50%;
padding: 0.9px;
    width: 16px;
    height: 16px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; font-weight: bold;
`;

const ConfirmButton = styled(Button)`
width: 50%; color: white !important; background-color: ${({paint}) => paint ? paint : 'grey'}!important;
`;

const AlertIconContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-content: center;
    cursor: pointer;
`;

const BaseModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 34px;
  min-height: 61px;
  align-items: center;
  font-weight: 100;
`;

const ClickableId = styled.span`
  cursor: pointer;
  color: #1d70b8;
  text-decoration: underline;
`;

const weekDaysData = [
    {value: 'M', selected: false},
    {value: 'T', selected: false},
    {value: 'W', selected: false},
    {value: 'T', selected: false},
    {value: 'F', selected: false},
    {value: 'S', selected: false},
    {value: 'S', selected: false},
];

const filterOptions = [
    {searchName: 'favourite', name: 'Favourites', value: true, icon: {name: 'star', color: 'yellow'}},
    {searchName: 'alert', name: 'Alerts', value: true, icon: {name: 'fontello icon-alert-bell', color: 'red'}},
];

const alertDataModalInitial = {open: false, deviceId: null, alertId: null};

const ManageDevices = (props) => {
    useLayoutEffect(() => {
        const initialQuery = {skip: 0, limit: limit};
        if(props.groups.persistedGroups && props.groups.persistedGroups.length > 0) {
            initialQuery.groups = props.groups.persistedGroups;
        }
        props.devicesGet(initialQuery);
        props.lastWakeupsGet();
        props.groupsGet(false);
        props.tagsGet();
    }, []);

    const clickableId = (id) => {
        return <ClickableId onClick={() => setDeviceDetails(id)}>{simplifyId(id)}</ClickableId>
    }

    const limit = 25;
    let [weekDays, setWeekDays] = useState(weekDaysData);
    let [modals, setModal] = useState({});
    let [searchValue, setSearchValue] = useState('');
    let [expandedLabel, setExpandedLabel] = useState(null);
    let [statusFilters, setStatusFilters] = useState([]);
    let [sortByValue, setSortByValue] = useState(null);
    let [checked, setChecked] = useState([]);
    let [hibernateEnabled, setHibernateEnabled] = useState(false);
    let [hibernateConfirm, setHibernateConfirm] = useState(false);
    let [alertDataModal, setAlertDataModal] = useState(alertDataModalInitial);
    let [allSelected, setAllSelected] = useState(false);
    let [deviceDetails, setDeviceDetails] = useState(null);
    let [sortValues, setSortValues] = useState({
        '_id': {
            name: '_id',
            direction: 'asc'
        },
        'installed': {
            name: 'installed',
            direction: 'asc'
        },
        'favourite': {
            name: 'favourite',
            direction: 'asc'
        },
        'name': {
            name: 'name',
            direction: 'asc'
        },
        'alert': {
            name: 'alert',
            direction: 'asc'
        },
        'operatingMode': {
            name: 'operatingMode',
            direction: 'asc'
        },
        'tamperState': {
            name: 'tamperState',
            direction: 'asc'
        },
        'lastWakeupTime': {
            name: 'tamperState',
            direction: 'asc'
        },
        'wakeupTimes': {
            name: 'wakeupTimes',
            direction: 'asc'
        },
        'wakeupTimesLength': {
            name: 'wakeupTimesLength',
            direction: 'asc'
        },
        'wakeupCount': {
            name: 'wakeupCount',
            direction: 'desc'
        },
        'wakeupCountThreshold': {
            name: 'wakeupCountThreshold',
            direction: 'asc'
        },
        'groups': {
            name: 'groups',
            direction: 'asc'
        }
    });
    const [selectedSort, setSelectedSort] = useState(null);
    const [searchFilters, setSearchFilters] = useState([]);
    const [pagination, setPagination] = useState({skip: 0, limit: limit});
    const [currentPage, setCurrentPage] = useState(1);
    // const [groupsQuery, setGroupsQuery] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);
    const [reportStolen, setReportStolen] = useState(false);

    const executeAction = data => {
        const selectTypes = {
            'edit': 'wakeupThreshold',
            'alert': 'clearAlert',
            'wakeups': 'wakeupTimes',
            'tamper': 'tamper',
            'mode': 'operatingMode',
            'shipping': 'operatingMode',
            'groups': 'group',
            'tag': 'tags',
        };

        const modalConfig = {...modals, [data.action]: true, data};

        if(allSelected && data.action) {
            modalConfig.selectType = selectTypes[data.action];
        }

        // console.log('DATA:', data);
        if (data.action) {
            setModal(modalConfig);
        }
    };

    const onCloseModal = () => {
        props.clearDeviceErrors();
        props.resetActioned();
        setModal({});
    };

    const change = (e) => {
        setSearchValue(e.target.value);
    };

    const submitNewSearch = (sortValue, filter = {}, groups) => {
        setCurrentPage(1);
        submitSearch(sortValue, filter, {skip: 0, limit: limit}, groups);
    }

    const submitSearch = (sortValue, filter = {}, paginate, groups) => {
        const {tagFilter, searchFilter} = filter;
        if (tagFilter) setStatusFilters(filter.tagFilter);
        if (sortValue) setSortByValue(sortValue);
        if (searchFilter) setSearchFilters(searchFilter);
        if (paginate) setPagination(paginate);
        if (groups) props.groupsSetPersisted(groups);

        const query = {
            search: searchValue,
            sort: sortValue || sortByValue,
            override: 1
        };

        if (groups && groups.length) {
            query.groups = groups.join(',');
        } else if (!groups && props.groups.persistedGroups && props.groups.persistedGroups.length) {
            query.groups = props.groups.persistedGroups.join(',');
        } else {
            query.groups = "";
        }

        if (tagFilter || (statusFilters && statusFilters.length > 0)) {
            query.tagfilter = tagFilter || statusFilters;
        }

        if (searchFilter && searchFilter.length > 0) {
            query.filters = searchFilter.map(filter => ({name: filter.searchName, value: filter.value}));
        }

        const getPaginationPoint = () => {
            const paginationPoint = {skip: 0, limit: limit};

            if (paginate && (!Number.isNaN(paginate.skip) && !Number.isNaN(paginate.limit))) {
                return paginate;
            }

            if (pagination && (!Number.isNaN(pagination.skip) && !Number.isNaN(pagination.limit))) {
                return pagination;
            }

            return paginationPoint;
        }

        const paginationPoint = getPaginationPoint();
        query.skip = paginationPoint.skip;
        query.limit = paginationPoint.limit;

        props.devicesGet(query);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitNewSearch();
        }
    };

    const clearSearch = () => {
        setCurrentPage(1);
        setPagination({skip: 0, limit: limit});
        setSearchValue('');
        props.devicesGet({skip: 0, limit: limit});
    };

    const onSort = (sortBy, selected) => {
        const direction = sortValues[sortBy].direction === 'asc' ? 'desc' : 'asc';
        setSelectedSort(selected);
        setSortValues({...sortValues, [sortBy]: {...sortValues[sortBy], direction}});
        setSortByValue({sortBy, direction});
        submitNewSearch({sortBy, direction});
    };

    const getMessage = (searching, parent) => {
        if (searching) return 'Your search produced no results.';
        return parent && !searching ? `You haven't added any devices.` : `You have no devices. Please contact your administrator.`
    };

    const formatWakeupTimes = () => {
        if (!props.devices.devices) return [];

        return props.devices.devices.filter(device => checked.find(check => check === device._id))
            .map(device => ({_id: device._id, wakeupTimes: device.wakeupTimes}));

    }

    const formatWeekDays = () => {
        return weekDays.map(day => day.selected ? 'X' : day.value).join('');
    }

    const getArrayLength = (array) => {
        return <div>{array && array.filter(item => item !== null).length}</div>;
    }

    const linkMap = (id) => {
        return <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}><Link to={`/locations/${id}`}><Icon size='large' color='green' name='map marker' /></Link></div>
    }

    const formatFavourite = (favourite) => {
        // return <div
        //     style={{
        //         display: 'flex',
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //     }}
        // >{favourite[0] ?
        //     <StyledIcon name='star' color='yellow' onClick={() => console.log(favourite[0], favourite[1])} />
        //     : <StyledIcon name='star outline' onClick={() => console.log(favourite[0], favourite[1])} />}
        // </div>;
        return <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Rating
                icon='star'
                className="rating-component"
                rating={favourite[0] ? 1 : 0}
                onClick={() => {
                    props.deviceSetFavourite({
                        favourite: favourite[0] ? false : true,
                        ids: [favourite[1]],
                    });
                }}
            />
        </div>;
    }

    const dateLessThan = (date1, date2) => {
        if(!date1 || !date2) return;

        const alertDate = new Date(date1);
        const assignedDate = new Date(date2);

        return (alertDate < assignedDate);
    }

    const formatAlert = (alert) => {
        if(dateLessThan(alert[2], alert[3])) return;

        return <AlertIconContainer
            onClick={() => {
                props.alertGet(alert[0]);
                setAlertDataModal({open: true, deviceId: alert[1], alertId: alert[0]});
            }}
        >
            {alert[0] ?
                <Icon
                    // className='fontello icon-alert-bell'
                    name='bell'
                    color='red'
                    style={{height: '100%'}}
                    onClick={() => {
                        props.alertGet(alert[0]);
                        setAlertDataModal({open: true, deviceId: alert[1], alertId: alert[0]});
                    }}
                />
                : null}
        </AlertIconContainer>;
    }

    const submitHibernate = (devices, wakeupDays) => {
        const hibernateObject = {
            devices, wakeupDays
        };

        props.hibernate(hibernateObject);
    }

    const protectedMenu = (key) => {
        const protectedOptions = {
            edit: {
                permissions: ['devices.edit_device.can_use'],
                groups: ['admin']
            },
            alert: {
                permissions: ['devices.clear_alert.can_use'],
                groups: ['local_admin', 'admin']
            },
            groups: {
                permissions: ['devices.change_group.can_use'],
                groups: ['local_admin', 'admin']
            },
            wakeups: {
                permissions: ['devices.change_wakeups.can_use'],
                groups: ['local_admin', 'admin']
            },
            mode: {
                permissions: ['devices.change_mode.can_use'],
                groups: ['local_admin', 'admin']
            },
            tamper: {
                permissions: ['devices.change_tamper.can_use'],
                groups: ['local_admin', 'admin']
            },
            history: {
                permissions: ['devices.install_history.can_use'],
                groups: ['local_admin', 'admin']
            },
            notes: {
                permissions: ['devices.notes.can_use'],
                groups: ['local_admin', 'admin']
            },
            status: {
                permissions: ['devices.add_status_tag.can_use'],
                groups: ['local_admin', 'admin']
            },
            shipping: {
                permissions: ['devices.change_mode.can_use'],
                groups: ['local_admin', 'admin']
            },
            delete: {
                permissions: ['devices.remove.can_use'],
                groups: ['admin']
            },
        };

        return isProtected(key, props.user, protectedOptions);
    }

    const renderWeekDays = (days, setDays) => {
        return days.map((day, index) => day.selected ? <WeekDaySelected
            key={'wd' + index}
            onClick={() => {
                const newDays = days.map((d, i) => {
                    // console.log(d, i, i === index);
                    return i === index ? {...d, selected: !d.selected} : d;
                })
                // console.log(newDays);
                setDays(newDays);
            }}>{day.value}</WeekDaySelected> : <WeekDay key={'wd' + index} onClick={() => {
            const newDays = days.map((d, i) => {
                return i === index ? {...d, selected: !d.selected} : d;
            })
            setDays(newDays);
        }}>{day.value}</WeekDay>);
    }

    const displayAlertData = (alert) => {
        const device = props.devices
            && props.devices.devices
            && props.devices.devices.find(device => device._id === alert.deviceId);

        if (!device) {
            return <></>;
        }

        const RenderAlert = ({title, date, icon, iconOverride, description}) => (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <span style={{fontWeight: 'bold', fontSize: '1.3em'}}>
                    {!iconOverride ? <Icon name={icon}/> : null}
                    {iconOverride ? iconOverride : null}
                    {title}
                </span>
                <span style={{fontSize: '1em', color: 'rgba(0,0,0,.4)'}}>{moment(date).format('LLLL')}</span>
                <span style={{marginTop: 10, textAlign: 'center', fontSize: '0.9em'}}>{description}</span>
            </div>
        )

        let renderedAlert;

        switch (alert.type) {
            case 'tamper':
                renderedAlert = <RenderAlert
                    title='Tamper Alert'
                    date={alert.dateTime}
                    icon='fontello icon-alert-bell'
                    description={
                        <span>A tamper alert was activated for <b>{device.name || simplifyId(alert.deviceId)}</b> at the following location: <br/><br/><b>{alert.location}</b></span>}
                />;
                break;
            case 'battery':
                renderedAlert = <RenderAlert
                    title='Battery Alert'
                    icon='battery low'
                    date={alert.dateTime}
                    description={
                        <span>The battery level for <b>{device.name || simplifyId(alert.deviceId)}</b> is running low: <span
                            style={{color: 'red'}}><b>{alert.batteryLevel || 0}%</b></span></span>}
                />;
                break;
            case 'geofence':
                renderedAlert = <RenderAlert
                    title='Geofence Alert'
                    icon='map marker alternate'
                    date={alert.dateTime}
                    description={
                        alert.detail && alert.detail.inside ?
                            <span>A geofence alert was generated when <b>{device.name || simplifyId(alert.deviceId)}</b> entered the geofence at the following location: <br/><br/><b>{alert.location}</b></span> :
                            <span>A geofence alert was generated when <b>{device.name || simplifyId(alert.deviceId)}</b> left the geofence and is now at the following location: <br/><br/><b>{alert.location}</b></span>
                    }
                />;
                break;
            case 'endoflife':
                renderedAlert = <RenderAlert
                    title='End of Life Alert'
                    date={alert.dateTime}
                    iconOverride={
                        <Icon.Group>
                            <Icon name='battery three quarters' />
                            <Icon corner name='x' color='red' />
                        </Icon.Group>
                    }
                    description={
                        alert.detail && alert.detail.inside ?
                            <span>An End of Life alert has been generated by <b>{device.name || simplifyId(alert.deviceId)}</b> at the following location: <br/><br/><b>{alert.location}</b>. <br /><br />The device has used up all of its guaranteed wakeups and needs replacing.</span> :
                            <span>An End of Life alert has been generated by <b>{device.name || simplifyId(alert.deviceId)}</b> at the following location: <br/><br/><b>{alert.location}</b>. <br /><br />The device has used up all of its guaranteed wakeups and needs replacing.</span>
                    }
                />;
                break;
            case 'belowthreshold':
                renderedAlert = <RenderAlert
                    title='Low Number of Wakeups Remaining'
                    date={alert.dateTime}
                    iconOverride={
                        <Icon.Group>
                            <Icon name='alarm' />
                            <Icon corner name='exclamation' color='red' />
                        </Icon.Group>
                    }
                    description={
                        alert.detail && alert.detail.inside ?
                            <span>The device <b>{device.name || simplifyId(alert.deviceId)}</b> is running low on wakeups at the following location: <br/><br/><b>{alert.location}</b>. <br /><br />The device is approaching the end of its guaranteed wakeup limit and will soon require replacement.</span> :
                            <span>The device <b>{device.name || simplifyId(alert.deviceId)}</b> is running low on wakeups at the following location: <br/><br/><b>{alert.location}</b>. <br /><br />The device is approaching the end of its guaranteed wakeup limit and will soon require replacement.</span>
                    }
                />;
                break;
            default:
                break;
        }


        return <div>{renderedAlert}</div>;
    }

    const calcBalance = (data) => {
        return <div>{data ? 1095 - data : 1095}</div>;
    }

    const renderStatus = (statuses) => {
        const [id, tags] = statuses;

        const renderedTags = tags && tags.length > 0 && props.tags && props.tags.tags && props.tags.tags.length > 0 ?
            tags.map((tag, i) => {
                const tagData = props.tags.tags.find(t => t._id === tag);

                return tagData ? <Popup
                    key={'rtags' + i}
                    content={tagData.name || ''}
                    trigger={<Label size="tiny"
                                    circular
                                    onMouseEnter={() => setExpandedLabel(id)}
                                    onMouseLeave={() => setExpandedLabel(null)}
                                    style={{
                                        padding: 2,
                                        color: 'white',
                                        backgroundColor: tagData.colour,
                                        marginRight: 5
                                    }}>{tagData.name && tagData.name.length > 0 ? tagData.name[0] :
                        <span>&nbsp;</span>}</Label>}/> : null;
            }) : null;


        return <span>
            {renderedTags}
      </span>;
    };

    const renderPagination = (<Container textAlign="right">
                    <span style={{marginRight: 20}}>
                        {pagination.skip + 1}-{pagination.skip + pagination.limit < props.devices.documentCount ?
                        pagination.skip + pagination.limit
                        : props.devices.documentCount} of {props.devices.documentCount || 0}
                    </span>
        <Pagination
            style={{marginBottom: 15}}
            boundaryRange={1}
            activePage={currentPage}
            ellipsisItem={null}
            firstItem={undefined}
            lastItem={undefined}
            prevItem={undefined}
            nextItem={undefined}
            siblingRange={1}
            totalPages={Math.ceil(props.devices.documentCount / limit)}
            onPageChange={(e, {activePage}) => {
                // setPagination({skip: ((activePage-1) * 50), limit: 50});
                setCurrentPage(activePage);

                submitSearch(sortByValue, {
                    tagFilter: statusFilters,
                    searchFilter: searchFilters
                }, {skip: ((activePage - 1) * limit), limit: limit});
                // props.devicesGet({skip: ((activePage-1) * 50), limit: 50});
            }}
        />
    </Container>)

    const extractQuery = () => {
        const query = {
            override: 1
        };

        if(statusFilters) {
            query.tagfilter = statusFilters;
        }

        if(searchFilters) {
            query.filters = searchFilters.map(filter => ({name: filter.searchName, value: filter.value}));
        }

        if (props.groups.persistedGroups && props.groups.persistedGroups.length) {
            query.groups = props.groups.persistedGroups.join(',');
        }

        return query;
    }

    const actionSelectAll = (obj) => {
        const {_ids, ids, ...payload} = obj || {};
        const selectType = modals.selectType;
        const query = extractQuery();

        // console.log('TESTING SELECT ALL: ', payload);
        // console.log('TESTING SELECT ALL: ', selectType);
        // console.log(query);

        props.deviceSelectAll({body: payload, selectType, query});
    };

    const SelectedItems = (props) => {
        let count;

        if(!props.allSelected && props.selected) {
            count = props.selected;
        }

        if(!props.allSelected && !props.selected) {
            count = 1;
        }

        if(allSelected) {
            count = props.count;
        }

        return <div style={{
            fontSize: 12,
            color: '#343534',
            width: '100%',
            textAlign: 'right',
            opacity: 0.8,
            marginTop: 10,
        }}>This will be applied to <span style={{fontSize: 14,fontWeight: 'bold',color: '#000000'}}>{count}</span> item{count > 1 ? 's' : null}</div>
    };

    const installedDevice = (data) => {
        const [installed, _id] = data;

        return installed ?
            <Icon size='large' color='green' name='check circle' onClick={() => setExpandedItem(expandedItem !== _id ? _id : null)} />
            : null;



        // return <div>{installed ? '+' : '-'}</div>
    }

    const getInstallerUser = (installerId, installers) => {
        if(!installers || installers && installers.length === 0) return '';

        const installer = installers.find(installerItem => installerItem._id === installerId);

        let installerString = '';

        installerString = installerString + (installer && installer.firstName ? `${installer.firstName} ` : '');
        installerString = installerString + (installer && installer.lastName ? `${installer.lastName} ` : '');
        installerString = installerString + (installer && installer.email ? `(${installer.email})` : '');

        return installerString;
    };

    const expandedItemView = (item) => {
        return <>
            {/*<pre>{JSON.stringify(items[i], null, 2)}</pre>*/}
            {/*<InstalledSnippet title='Status' content={items[i].installed ? <span style={{color: 'rgb(62, 186, 69)', fontWeight: 500}}>Installed</span> : null} />*/}
            <InstalledSnippet title='Install Date' content={item?.installationDate ? Moment.utc(item?.installationDate).local().format('LLL') : ''} />
            <InstalledSnippet title='Installed By' content={getInstallerUser(item.installedBy, props.devices.deviceInstallers)} />
            {item?.vehicleData?.make ? <InstalledSnippet title='Make' content={item.vehicleData.make} /> : null}
            {item?.vehicleData?.model ? <InstalledSnippet title='Model' content={item.vehicleData.model} /> : null}
            {item?.vin ? <InstalledSnippet title='VIN' content={item.vin} /> : null}
            {item?.plate ? <InstalledSnippet title='Asset ID' content={item.plate.toUpperCase()} /> : null}
            {item?.category ? <InstalledSnippet title='Category' content={item.category} /> : null}
            {item?.stockNumber ? <InstalledSnippet title='Stock Number' content={item.stockNumber} /> : null}
        </>
    }

    const deviceFound = (deviceId) => {
        props.deviceClearRecovery({deviceId});
    }
    const deviceClear = (deviceId) => {
        props.deviceClearRecovery({deviceId, cancel: true});
    }

    const operatingMode = (state) => {
        const [current, pending, _id, trackingStartTime, stolenState] = state;
        const _onClick = (e) => (extend) => {
            setChecked([]);
            setAllSelected(false);
            const data = props.devices.devices.find(device => device._id === _id);
            executeAction({action: 'mode', extend: extend, ...data })
        };

        const onExtendClick = (e) => {
            _onClick(e)(true);
        }
        const onClick = (e) => {
            _onClick(e)(false);
        }

        return _operatingMode([current, pending, trackingStartTime, _id, stolenState || 0], onClick, onExtendClick, deviceFound, deviceClear);
    }
    const handleClick = () => {
        const startDate = "2023-06-09T00:00Z";
        const endDate = "2023-06-15T00:00Z";

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ startDate, endDate })
        };

        fetch('https://zzzapi.intelizzz.co.uk/api/selectall?override=1&type=reconcile', requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Handle success
                // console.log('Request successful');
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            });
    };

    return (
        <Container fluid style={{padding: 5}}>
            <Container>
                {/*<Button onClick={handleClick}>Send Request</Button>*/}
                <Grid columns={2} padded>
                    <Grid.Column>
                        <Header>
                            <Divider fitted hidden/>
                            Device Management
                        </Header>
                <Container style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 5}} fluid>
                    <div style={{width: '100%', maxWidth: 269, zIndex: 1000}}>
                        <Dropdown
                            fluid
                            placeholder='Group'
                            selection
                            clearable
                            multiple
                            value={props.groups.persistedGroups}
                            onChange={(e, {value}) => {
                                setCurrentPage(1);
                                setPagination({skip: 0, limit: pagination.limit})
                                // change({groups: value}, true);
                                submitNewSearch(sortByValue, {tagFilter: statusFilters, searchFilter: searchFilters}, value);
                            }}
                            options={props.groups.groups.map(group => ({
                                key: group._id,
                                value: group._id,
                                text: group.name
                            }))}
                        />
                    </div>
                </Container>
                <Container fluid>
                    <Input
                        type='text'
                        placeholder='Search devices...'
                        icon={searchValue ? {name: 'cancel', link: true, onClick: clearSearch} : null}
                        style={{
                            marginTop: -2,
                            marginRight: 5,
                        }}
                        value={searchValue}
                        onChange={change}
                        onKeyDown={onKeyDown}
                    />
                    <Button color='black' content='Search' onClick={submitNewSearch}/>
                </Container>
                    </Grid.Column>
                    {/*<Grid.Column/>*/}
                <Grid.Column>
                    <Container fluid textAlign='right' style={{marginBottom: 15, marginTop: 15}}>
                        <Button
                            color={searchFilters.some(obj => obj.searchName === 'operatingMode') ? 'blue' : 'red'}
                            content='Locate'
                            icon={<Icon name='map marker alternate' style={{background: searchFilters.some(obj => obj.searchName === 'operatingMode') ? '#225eac' : '#ac2430'}} />}
                            labelPosition='right'
                            onClick={() => {
                                const operatingModeFilter = searchFilters.some(obj => obj.searchName === 'operatingMode');
                                const newSearchFilter = operatingModeFilter ?
                                    [ ...searchFilters.filter(obj => obj.searchName !== 'operatingMode') ]
                                    : [...searchFilters, { searchName: 'operatingMode', value: 'Tracking' }];
                                submitNewSearch(null, {
                                    tagFilter: statusFilters,
                                    searchFilter: newSearchFilter
                                });
                                // props.clearDeviceErrors();
                                // props.resetActioned();
                                // setModal({
                                //     ...modals,
                                //     add: true
                                // });
                            }}
                        />
                        <ProtectedComponent
                            permissions={['devices.hibernate.can_use']}
                            groups={['local_admin', 'admin']}
                        >
                            <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                                <Button
                                    disabled={!isHibernateTimezone()}
                                    active={hibernateEnabled}
                                    color='black'
                                    style={hibernateEnabled ? {backgroundColor: 'rgb(168, 111, 161)'} : {}}
                                    content='Hibernate'
                                    icon='fontello icon-intelizzz'
                                    labelPosition='right'
                                    onClick={() => {
                                        if (!hibernateEnabled || (hibernateEnabled && checked.length === 0)) {
                                            setWeekDays(weekDaysData);
                                            setHibernateEnabled(!hibernateEnabled);
                                        }

                                        if (hibernateEnabled && checked.length > 0) {
                                            setHibernateConfirm(true);
                                        }
                                    }}
                                />
                                <AnimatePresence>
                                    {hibernateEnabled ? <motion.div
                                        initial={{height: 0, marginTop: 0}}
                                        animate={{height: 27, marginTop: 7}}
                                        exit={{height: 0, marginTop: -4}}
                                        style={{
                                            padding: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            marginTop: 7,
                                            overflow: 'hidden'
                                        }}>
                                        {renderWeekDays(weekDays, setWeekDays)}
                                    </motion.div> : null}
                                </AnimatePresence>
                            </div>
                        </ProtectedComponent>
                        <Popup
                            style={{padding: 0}}
                            on='click'
                            trigger={<Button
                                color='black'
                                content='Filters'
                                icon='filter'
                                labelPosition='right'
                            />}>
                            <TickList
                                onLoad={(payload) => {
                                    props.tagCount(payload);
                                    props.filterCount();
                                }}
                                loadParams={{tags: props.tags.tags.map(tag => tag._id)}}
                                items={props.tags.tags || []}
                                filterOptions={filterOptions}
                                filters={searchFilters}
                                counts={{
                                    tags: props.tags.counts || {},
                                    filters: props.devices.filterCounts || {}
                                }}
                                selected={() => {
                                    const selected = []
                                    if (statusFilters && statusFilters.length > 0) {
                                        statusFilters.forEach(filter => {
                                            const idx = props.tags.tags.findIndex(tag => tag._id === filter);
                                            if (idx === 0 || idx) selected.push(idx);
                                        })
                                    }
                                    return selected;
                                }}
                                apply={(filters) => {
                                    submitNewSearch(null, filters);
                                }}
                            />
                        </Popup>
                        <ProtectedComponent
                            permissions={['devices.add_device.can_use']}
                            groups={['admin']}
                        >
                            <Button
                                color='black'
                                content='Add Device'
                                icon='add'
                                labelPosition='right'
                                onClick={() => {
                                    props.clearDeviceErrors();
                                    props.resetActioned();
                                    setModal({
                                        ...modals,
                                        add: true
                                    });
                                }}
                            />
                        </ProtectedComponent>
                    </Container>
                {renderPagination}
                </Grid.Column>
                </Grid>
                {!!(props.devices.devices && props.devices.devices.length > 0) ?
                    <ItemsList
                        headings={[
                            {
                                title: <Icon name='question' />,
                                field: ['installed', '_id'],
                                function: installedDevice,
                                empty: '',
                                sortName: 'installed',
                                sortDirection: sortValues['installed'].direction,
                                sortFunc: onSort,
                                selected: selectedSort

                            },
                            {
                                title: <Icon name='bell' />,
                                field: ['alert', '_id', 'alertDate', 'dateAssigned'],
                                function: formatAlert,
                                empty: '',
                                icon: true,
                                sortName: 'alert',
                                sortDirection: sortValues['alert'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: <Icon name="star" />,
                                field: ['favourite', '_id'],
                                function: formatFavourite,
                                empty: '',
                                icon: true,
                                sortName: 'favourite',
                                sortDirection: sortValues['favourite'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'ID',
                                field: '_id',
                                function: clickableId,
                                empty: '',
                                sortName: '_id',
                                sortDirection: sortValues['_id'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: <Icon name="map marker alternate" />,
                                field: ['_id'],
                                function: linkMap,
                                empty: '',
                                icon: true,
                            },
                            {
                                title: 'Device Name',
                                field: 'name',
                                empty: '',
                                sortName: 'name',
                                sortDirection: sortValues['name'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'Group',
                                field: 'groupId',
                                function: getGroupName(props.groups.groups),
                                empty: 'No group set',
                                sortName: 'groups',
                                sortDirection: sortValues['groups'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'Last Wakeup',
                                field: 'lastWakeupTime',
                                function: formatLastWakeup,
                                empty: 'Not found',
                                sortName: 'lastWakeupTime',
                                sortDirection: sortValues['lastWakeupTime'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'Next Wakeup',
                                field: ['wakeupTimes', 'pendingWakeupTimes'],
                                function: getNextTime,
                                empty: 'No times set',
                                sortName: 'wakeupTimes',
                                sortDirection: sortValues['wakeupTimes'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'No. of Wakeups',
                                field: 'wakeupTimes',
                                function: getArrayLength,
                                empty: '0',
                                sortName: 'wakeupTimesLength',
                                sortDirection: sortValues['wakeupTimesLength'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'Balance',
                                field: 'wakeupCount',
                                function: calcBalance,
                                empty: 'No Data',
                                sortName: 'wakeupCount',
                                sortDirection: sortValues['wakeupCount'].direction,
                                sortFunc: onSort,
                                selected: selectedSort,
                                oppositeSort: true,
                            },
                            {
                                title: 'Low Balance',
                                field: 'wakeupCountThreshold',
                                empty: 'Unknown',
                                sortName: 'wakeupCountThreshold',
                                sortDirection: sortValues['wakeupCountThreshold'].direction,
                                sortFunc: onSort,
                                selected: selectedSort,
                            },
                            {
                                title: 'Operating Mode',
                                field: ['operatingMode', 'pendingOperatingMode', '_id', 'trackingStartTime', 'stolenState'],
                                function: operatingMode,
                                sortName: 'operatingMode',
                                sortDirection: sortValues['operatingMode'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'Tamper',
                                field: ['tamperState', 'pendingTamperState'],
                                function: tamperState,
                                sortName: 'tamperState',
                                sortDirection: sortValues['tamperState'].direction,
                                sortFunc: onSort,
                                selected: selectedSort
                            },
                            {
                                title: 'Status',
                                field: ['_id', 'tags'],
                                function: renderStatus,
                            }
                        ]}

                        items={props.devices.devices}
                        itemCount={props.devices.documentCount}
                        itemType={'devices'}
                        itemLimit={limit}
                        allSelectedAction={((changed) => setAllSelected(changed))}
                        expanded={expandedItem}
                        // setExpanded={setExpandedItem}
                        expandedView={expandedItemView}

                        data={{installers: props.devices.deviceInstallers}}
                        overlay={(item, opacity = 1) => {
                            if (item &&
                                ((item.pendingWakeupDays && item.pendingWakeupDays.includes('X')) || (
                                    item.wakeupDays && item.wakeupDays.includes('X')))
                            ) {
                                return `rgba(177, 80, 144, ${opacity})`;
                            }

                            if (hibernateEnabled && checked.find(check => check === item._id)) {
                                return `rgba(177, 80, 144, ${opacity})`;
                            }

                            if (item && item.alert && item.alertDate && !(dateLessThan(item.alertDate, item.dateAssigned))) {
                                return `rgb(249, 98, 109, ${opacity})`;
                            }

                            return null;
                        }}

                        checkColor={(item) => {
                            if (item &&
                                ((item.pendingWakeupDays && item.pendingWakeupDays.includes('X')) || (
                                    item.wakeupDays && item.wakeupDays.includes('X')))
                            ) {
                                return 'white';
                            }

                            if (hibernateEnabled && checked.find(check => check === item._id)) {
                                return 'white';
                            }

                            if (item && item.alert && item.alertDate) {
                                return 'white';
                            }

                            return null;
                        }}

                        selectable

                        actions={[
                            {
                                key: 'edit',
                                icon: 'edit',
                                text: 'Edit Device Details',
                                color: 'black',
                                action: 'edit',
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'alert',
                                icon: 'fontello icon-alert-bell',
                                text: 'Clear Device Alert',
                                color: 'black',
                                action: 'alert',
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'groups',
                                icon: 'sitemap',
                                text: 'Manage Hierarchy',
                                color: 'black',
                                action: 'groups',
                                protected: protectedMenu,
                                selectable: true
                            },
                            {
                                key: 'wakeups',
                                icon: 'clock',
                                text: 'Change Wake-up Times',
                                color: 'black',
                                action: 'wakeups',
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'mode',
                                icon: 'toggle off',
                                text: 'Change Operating Mode',
                                color: 'black',
                                action: 'mode',
                                hideItem: 'stolenState',
                                hideCondition: 0,
                                hideNot: false,
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'tamper',
                                icon: 'alarm',
                                text: 'Change Tamper State',
                                color: 'black',
                                action: 'tamper',
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'history',
                                icon: 'history',
                                text: 'Installation History',
                                color: 'black',
                                action: 'history',
                                selectable: false,
                                protected: protectedMenu,
                            },
                            {
                                key: 'notes',
                                icon: 'book',
                                text: 'Device Notes',
                                color: 'black',
                                action: 'notes',
                                selectable: false,
                                protected: protectedMenu,
                            },
                            {
                                key: 'status',
                                icon: 'tags',
                                text: 'Add Status Tag',
                                color: 'black',
                                action: 'tag',
                                selectable: true,
                                protected: protectedMenu,
                            },

                            {
                                key: 'shipping',
                                icon: 'shipping',
                                text: 'Reset to Shipping Mode',
                                color: 'black',
                                action: 'shipping',
                                selectable: true,
                                protected: protectedMenu,
                            },
                            {
                                key: 'delete',
                                icon: 'delete',
                                text: 'Remove Device',
                                color: 'black',
                                action: 'delete',
                                protected: protectedMenu,
                                selectable: false
                            }
                        ]}

                        callback={executeAction}
                        setChecked={setChecked}

                    /> : <EmptyBoard
                        loader={props.devices.isLoadingDevices}
                        icon={searchValue && searchValue.length > 0 ? 'search' : 'stop'}
                        message={getMessage(((searchValue && searchValue.length > 0) || (statusFilters && statusFilters.length > 0) || (searchFilters && searchFilters.length > 0)), hasAccess(props.user, ['devices.add_device.can_use'], ['local_admin', 'admin']))}
                        button={
                            hasAccess(props.user, ['devices.add_device.can_use'], ['local_admin', 'admin']) && !((searchValue && searchValue.length > 0) || (statusFilters && statusFilters.length > 0) || (searchFilters && searchFilters.length > 0)) ? <Button
                                color='blue'
                                content='Add Your First Device'
                                icon='add'
                                labelPosition='right'
                                onClick={() => {
                                    props.clearDeviceErrors();
                                    props.resetActioned();
                                    setModal({
                                        ...modals,
                                        add: true
                                    });
                                }}
                                size='large'
                            /> : null
                        }
                    />
                }
                <Divider hidden/>
                {renderPagination}
            </Container>


            <Divider hidden/>

            <BaseModal
                color='black'
                content='Remove Device'
                icon='delete'
                labelPosition='left'
                title='Remove Device'
                open={modals.delete}
                close={() => setModal({...modals, delete: false})}
                onClose={onCloseModal}>
                {modals.data ?
                    <RemoveDevice
                        submit={props.deviceRemove}
                        _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        name={modals.data.name}
                        buttonText={'Remove'}
                        color='black'
                        error={props.devices.error}
                        loading={props.devices.isRemovingDevice}
                        close={() => {
                            setModal({...modals, delete: false});
                            // onCloseModal();
                        }}
                    /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Device Installation History'
                icon='history'
                labelPosition='left'
                title={`Device Installation History - ${modals.data && modals.data.name ? modals.data.name : ''} - ${modals.data && modals.data._id ? simplifyId(modals.data._id) : ''}`}
                open={modals.history}
                close={() => setModal({...modals, history: false})}
                onClose={onCloseModal}
                closeButton={() => setModal({...modals, history: false})}
                size="small"
            >
                <ScrollingModal scrolling={!!!props.devices.isGettingInstallationHistory}>
                    {modals.data ? <InstallationHistory deviceId={modals.data._id}/> : null}
                </ScrollingModal>
            </BaseModal>

            <Modal
                size="mini"
                open={modals.alert}
                onClose={onCloseModal}
            >
                <Modal.Content
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <span style={{fontWeight: 'bold', fontSize: '1.3em'}}>Confirm Clear?</span>
                    <span style={{marginTop: 3, width: '90%', textAlign: 'center', fontSize: '0.9em'}}>Are you sure you want to clear the alerts of one or more devices?</span>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 18,
                        width: '60%'
                    }}>
                        <ConfirmButton
                            paint={'rgb(76, 176,108)'}
                            onClick={() => {
                                if(!allSelected) {
                                    const ids = modals.data && modals.data.payload && Array.isArray(modals.data.payload) ? modals.data.payload.map(dev => dev._id) : [modals.data._id];
                                    props.deviceClearAlert({
                                        ids
                                    });
                                }

                                if(allSelected) {
                                    actionSelectAll();
                                }

                                setModal({...modals, alert: false});
                            }}
                        >Yes</ConfirmButton>
                        <ConfirmButton
                            paint={'rgb(217, 42, 43)'}
                            onClick={() => setModal({...modals, alert: false})}
                        >Cancel</ConfirmButton>
                    </div>
                    <SelectedItems
                        allSelected={allSelected}
                        count={props.devices.documentCount}
                        selected={modals?.data?.payload?.length}
                    />
                </Modal.Content>
            </Modal>

            <Modal
                size="mini"
                open={modals.shipping}
                onClose={onCloseModal}
            >
                <Modal.Content
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <span style={{fontWeight: 'bold', fontSize: '1.3em'}}>Reset Device to Shipping Mode?</span>
                    <span style={{marginTop: 3, width: '90%', textAlign: 'center', fontSize: '0.9em'}}>Are you sure you want to reset the operating mode back to shipping for one or more devices?</span>
                    <span style={{marginTop: 5, width: '92%', textAlign: 'center', fontSize: '0.75em'}}>(Please note: Devices in shipping mode will only report LBS locations which are not as accurate as GNSS.)</span>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 18,
                        width: '60%'
                    }}>
                        <ConfirmButton
                            paint={'rgb(76, 176,108)'}
                            onClick={() => {
                                const ids = modals.data && modals.data.payload && Array.isArray(modals.data.payload) ? modals.data.payload.map(dev => dev._id) : [modals.data._id];

                                const shippingObj = {
                                    operatingMode: 'Shipping',
                                    _ids: ids
                                }
                                if(!allSelected) {
                                    props.deviceSetOperMode(shippingObj);
                                }

                                if(allSelected) {
                                    actionSelectAll(shippingObj);
                                }

                                setModal({...modals, shipping: false});
                            }}
                        >Yes</ConfirmButton>
                        <ConfirmButton
                            paint={'rgb(217, 42, 43)'}
                            onClick={() => setModal({...modals, shipping: false})}
                        >Cancel</ConfirmButton>
                    </div>
                    <SelectedItems
                        allSelected={allSelected}
                        count={props.devices.documentCount}
                        selected={modals?.data?.payload?.length}
                    />
                </Modal.Content>
            </Modal>

            <BaseModal
                color='black'
                content='Device Notes'
                icon='book'
                labelPosition='left'
                title={`Device Notes - ${modals.data && modals.data.name ? modals.data.name : ''} - ${modals.data && modals.data._id ? simplifyId(modals.data._id) : ''}`}
                open={modals.notes}
                close={() => setModal({...modals, notes: false})}
                onClose={onCloseModal}
                closeButton={() => setModal({...modals, notes: false})}
                size="small"
            >
                <ScrollingModal scrolling={!!!props.devices.isGettingNotes}>
                    {modals.data ? <DeviceNotes deviceId={modals.data._id}/> : null}
                </ScrollingModal>
            </BaseModal>

            <BaseModal
                color='black'
                content='Edit Device'
                icon='edit'
                labelPosition='left'
                title='Edit Device'
                open={modals.edit}
                close={() =>setModal({...modals, edit: false})}
                onClose={onCloseModal}>
                {modals.data ?
                    <AddDevice
                        submit={allSelected ? actionSelectAll : props.deviceUpdate}
                        _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        name={modals.data.name}
                        data={modals.data}
                        buttonText={'Save'}
                        color='black'
                        icon='edit'
                        error={props.devices.error}
                        loading={props.devices.isUpdatingDevice || props.devices.isSelectingAll}
                        close={() => {
                            setModal({...modals, edit: false});
                            // onCloseModal();
                        }}
                    /> : null}
                <SelectedItems
                    allSelected={allSelected}
                    count={props.devices.documentCount}
                    selected={modals?.data?.payload?.length}
                />
            </BaseModal>
            <BaseModal
                size='small'
                color='black'
                content='Wake Up Times'
                icon='clock'
                labelPosition='left'
                title='Change Device Wakeup Times'
                open={modals.wakeups}
                close={() => setModal({...modals, wakeups: false})}
                onClose={onCloseModal}>
                {modals.data ?
                    <WakeupTimes
                        submit={allSelected ? actionSelectAll : null}
                        deviceIds={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        wakeupTimes={modals.data.wakeupTimes}
                        wakeupDays={modals.data.wakeupDays}
                        pendingWakeupTimes={modals.data.pendingWakeupTimes}
                        pendingWakeupDays={modals.data.pendingWakeupDays}
                        configure={modals.data.configure}
                        loading={props.devices.isUpdatingTimes}
                        times={modals.data.wakeupTimes}
                        close={() => {
                            setModal({...modals, wakeups: false});
                            // onCloseModal();
                        }}
                    /> : null
                }
                <SelectedItems
                    allSelected={allSelected}
                    count={props.devices.documentCount}
                    selected={modals?.data?.payload?.length}
                />
            </BaseModal>
            <BaseModal
                color='black'
                content='Group'
                icon='sitemap'
                labelPosition='left'
                title='Manage Device Hierarchy'
                open={modals.groups}
                close={() => setModal({...modals, groups: false})}
                onClose={onCloseModal}>{
                modals.data ?
                    <AddToGroup
                        submit={allSelected ? actionSelectAll : props.deviceGroupUpdate}
                        icon='edit'
                        buttonText='Save'
                        color='black'
                        _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        groups={props.groups.groups}
                        groupCreate={props.groupCreate}
                        groupId={modals.data.groupId}
                        loading={props.devices.isUpdatingDevice || props.devices.isSelectingAll}
                        close={() => {
                            setModal({...modals, groups: false});
                            // onCloseModal();
                        }}
                    /> : null}
                <SelectedItems
                    allSelected={allSelected}
                    count={props.devices.documentCount}
                    selected={modals?.data?.payload?.length}
                />
            </BaseModal>
            <BaseModal
                color='black'
                content='State'
                icon='alarm'
                labelPosition='left'
                title='Tamper Sensor'
                open={modals.tamper}
                close={() => setModal({...modals, tamper: false})}
                onClose={onCloseModal}
            >{
                modals.data ?
                    <EditState
                        submit={allSelected ? actionSelectAll : props.deviceSetTamper}
                        title='Tamper Sensor'
                        buttonText='Set Tamper State'
                        color='black'
                        _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        onState='On'
                        offState='Off'
                        clearState='Tamper'
                        stateType='tamperState'
                        state={modals.data.tamperState}
                        pendingState={modals.data.pendingTamperState}
                        loading={props.devices.isUpdatingDevice || props.devices.isSelectingAll}
                        times={modals.data.wakeupTimes}

                        colours={{
                            'On': {color: 'black', weight: 'bold'},
                            'Off': {color: 'grey', weight: 'normal'},
                            'Tamper': {color: 'red', weight: 'bold'},
                        }}
                        close={() => {
                            setModal({...modals, tamper: false});
                            // onCloseModal();
                        }}
                    /> : null}
                <SelectedItems
                    allSelected={allSelected}
                    count={props.devices.documentCount}
                    selected={modals?.data?.payload?.length}
                />
            </BaseModal>
            <BaseModal
                color='black'
                content='State'
                labelPosition='left'
                title={modals.data ? <BaseModalHeader>
                        {modals?.data?.operatingMode ? <Label
                        color={modals?.data?.operatingMode === 'Tracking' ? 'red' : modals?.data?.operatingMode === 'Normal' ? 'green' : 'grey'}
                        style={{position: 'absolute', top: 6, left: 1}}
                    >{getLocaleString(modals?.data?.operatingMode)}</Label> : null}
                    {modals?.data?._id ? <><span style={{fontWeight: '900'}}>{simplifyId(modals.data._id)}</span> {truncate(modals?.data?.name?.toUpperCase())}</> : null}
                    {modals?.data?.payload  ? <span style={{fontWeight: '900'}}>{modals.data.payload.length} SELECTED</span> : null}
                </BaseModalHeader> : ''}
                open={modals.mode}
                close={() => setModal({...modals, mode: false})}
                onClose={onCloseModal}
            >{
                modals.data ?
                    <OperatingMode
                        submit={allSelected ? actionSelectAll : props.deviceSetOperMode}
                        title='Operating Mode'
                        buttonText='Set Operating Mode'
                        color='black'
                        _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        onState='Tracking'
                        offState='Normal'
                        stateType='operatingMode'
                        state={modals.data.operatingMode}
                        pendingState={modals.data.pendingOperatingMode}
                        loading={props.devices.isUpdatingDevice || props.devices.isSelectingAll}
                        times={modals.data.wakeupTimes}
                        frequency={modals.data.frequency}
                        extend={modals.data.extend}
                        pendingFrequency={modals.data.pendingFrequency}
                        data={modals.data}
                        close={() => {
                            setModal({...modals, mode: false});
                            // onCloseModal();
                        }}
                        arcEnabled={arcEnabled}
                        arcSubmit={(id) => setReportStolen(id)}
                        colours={{
                            'Tracking': {color: 'red', weight: 'bold'},
                            'Normal': {color: 'black', weight: 'bold'},
                            'Shipping': {color: 'grey', weight: 'normal'},
                        }}
                    /> : null}
                <SelectedItems
                    allSelected={allSelected}
                    count={props.devices.documentCount}
                    selected={modals?.data?.payload?.length}
                />
            </BaseModal>

            <BaseModal
                color='black'
                content='Add Device'
                icon='add'
                labelPosition='right'
                title='Add Device'
                open={modals.add}
                close={() => setModal({...modals, add: false})}
                onClose={onCloseModal}
            >
                <AddDevice
                    submit={props.deviceAdd}
                    icon='add'
                    title='Add Device'
                    buttonText='Add Device'
                    newDevice={true}
                    color='black'
                    error={props.devices.error}
                    loading={props.devices.isUpdatingDevice}
                    close={() => {
                        setModal({...modals, add: false});
                        // onCloseModal();
                    }}
                />
            </BaseModal>

            <BaseModal
                color='blue'
                content='Add First Device'
                icon='add'
                labelPosition='right'
                title='Add First Device'
                open={modals.addFirst}
                close={() => setModal({...modals, addFirst: false})}
                onClose={onCloseModal}
            >
                <AddDevice
                    submit={props.deviceAdd}
                    icon='add'
                    title='Add Your First Device'
                    buttonText='Add First Device'
                    color='blue'
                    error={props.devices.error}
                    loading={props.devices.isUpdatingDevice}
                    close={() => {
                        setModal({...modals, addFirst: false});
                        // onCloseModal();
                    }}
                />
            </BaseModal>

            <BaseModal
                color='black'
                content='Add Status Tag'
                icon='tags'
                labelPosition='right'
                title='Add Status Tag'
                open={modals.tag}
                close={() => setModal({...modals, tag: false})}
                onClose={onCloseModal}
                expanded
            >
                {
                    modals.data ?
                        <StatusLabel
                            submit={allSelected ? actionSelectAll : props.deviceTagsUpdate}
                            tags={props.tags.tags}
                            icon='edit'
                            buttonText='Save'
                            color='black'
                            _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                            tagCreate={props.tagAdd}
                            tagRemove={props.tagRemove}
                            deviceTags={modals.data.tags}
                            updating={props.devices.isUpdatingDeviceTags || props.devices.isSelectingAll}
                            loading={props.tags.isAddingTag}
                            close={() => {
                                setModal({...modals, tag: false});
                                // onCloseModal();
                            }}
                        /> : null}
                <div style={{marginRight: 10, marginBottom: 10}}><SelectedItems
                    allSelected={allSelected}
                    count={props.devices.documentCount}
                    selected={modals?.data?.payload?.length}
                /></div>
            </BaseModal>

            <Modal
                size="mini"
                open={hibernateConfirm}
                onClose={() => {
                    setHibernateConfirm(false);
                }}
            >
                <Modal.Content
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <span style={{fontWeight: 'bold', fontSize: '1.3em'}}>Confirm selection?</span>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 18,
                        width: '60%'
                    }}>
                        <ConfirmButton
                            paint={'rgb(76, 176,108)'}
                            onClick={() => {
                                submitHibernate(formatWakeupTimes(), formatWeekDays());
                                setHibernateConfirm(false);
                                setHibernateEnabled(false);
                                setWeekDays(weekDaysData);
                            }}
                        >Yes</ConfirmButton>
                        <ConfirmButton
                            paint={'rgb(217, 42, 43)'}
                            onClick={() => setHibernateConfirm(false)}
                        >Cancel</ConfirmButton>
                    </div>
                    <span style={{marginTop: 18, fontWeight: 'bold', fontSize: '0.9em'}}>Important</span>
                    <span style={{marginTop: 3, width: '90%', textAlign: 'center', fontSize: '0.9em'}}>The changes to your selected devices will take effect on the next wake up.</span>
                </Modal.Content>
            </Modal>

            <Modal
                size="small"
                open={alertDataModal.open}
                onClose={() => {
                    setAlertDataModal(alertDataModalInitial);
                }}
            >
                <Modal.Content
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    {/*<pre>{JSON.stringify(props.alerts.alert, null, 2)}</pre>*/}
                    {/*<pre>{JSON.stringify(props.devices.devices.find(device => device._id === props.alerts.alert.deviceId), null, 2)}</pre>*/}
                    {displayAlertData(props.alerts.alert)}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 18,
                        width: '60%'
                    }}>
                        <ConfirmButton
                            paint={'rgb(76, 176,108)'}
                            onClick={() => {
                                // console.log(alertDataModal);
                                props.deviceClearAlert({
                                    ids: [alertDataModal.deviceId]
                                })
                                setAlertDataModal(alertDataModalInitial);
                            }}
                        >Clear Alert</ConfirmButton>
                        <ConfirmButton
                            paint={'rgb(217, 42, 43)'}
                            onClick={() => setAlertDataModal(alertDataModalInitial)}
                        >Close</ConfirmButton>
                    </div>
                </Modal.Content>
            </Modal>

            <DeviceDetailsModal open={!!deviceDetails} id={deviceDetails} close={() => setDeviceDetails(null)} />

            <BaseModal
                color='black'
                content='Report as Stolen'
                icon='crosshairs'
                labelPosition='left'
                title='Report as Stolen'
                // closeButton={() => setReportStolen(null)}
                open={!!reportStolen}
                close={() => setReportStolen(null)}
                onClose={onCloseModal}
            >

                <ReportStolen
                    submit={props.deviceRecovery}
                    buttonText='Report Stolen'
                    color='black'
                    deviceId={reportStolen}
                    deviceName={getDeviceName(props.devices.devices, reportStolen)}
                    error={null}
                    loading={props.devices.isGettingRecoveryCode}
                    close={() => {
                        setReportStolen(null);
                    }}
                />
            </BaseModal>
        </Container>
    );
};

const getDeviceName = (devices, _id) => {
    const device = devices.find(device => device._id === _id);
    if (device && device.name) return device.name;
};

const getGroupName = groups => (_id) => {
    if (!_id) return '';
    const res = groups.find(group => group._id === _id);
    return res && res.name ? res.name : '';
};

const getNextTime = (timesObj) => {
    const [times, pendingTimes] = timesObj;

    const durations = times.map(time => {
        // Calculate duration based on UTC
        const start = Moment.utc(new Date(), "h:mm a");
        const end = Moment.utc(time, "h:mm a");

        const duration = Moment.duration(end.diff(start));

        // Re-create the time as UTC and then give us the local time based on that
        const local = Moment.utc(time, "h:mm a").local().format("HH:mm");

        return {time, local, duration: duration.asMinutes()};
    }).filter(duration => {
        return duration.duration;
    });

    if (durations.length > 0) {
        const sorted = durations.sort((a, b) => (a.duration > b.duration) ? 1 : -1);
        const resorted = sorted.filter(duration => duration.duration > 0).concat(sorted.filter(duration => duration.duration <= 0));
        // return <span style={{color: 'orange'}}>{resorted[0].local}<br/><span style={{fontSize: '0.8rem'}}>({resorted[0].time} UTC)</span></span>;
        return !pendingTimes ? <span>{resorted[0].local}</span> : <Label color='orange'>{resorted[0].local}</Label>;
    }

    return null;
};

const modeLabel = (colors, current, pending, onClick) => (
    pending && current !== pending ? <>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Button as='div' labelPosition='left' compact size='mini' onClick={onClick}>
                <Label as='a' pointing='right' color={colors[current]}>{getLocaleString(current)}</Label>
                <Button icon color={colors[pending]} compact size='mini'>
                    <div style={{minWidth: 18}}>&nbsp;</div>
                </Button>
            </Button>
        </Responsive>

        <Responsive minWidth={Responsive.onlyTablet.minWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Button.Group vertical size='mini' onClick={onClick}>
                <Button as='div' labelPosition='left' compact size='mini'>
                    <Label as='a' pointing='below' color={colors[current]}>{getLocaleString(current)}</Label>
                </Button>
                <Button icon color={colors[pending]} compact size='mini'>
                    <div style={{minWidth: 18}}>&nbsp;</div>
                </Button>
            </Button.Group>
        </Responsive>

        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Button as='div' labelPosition='left' compact size='mini' onClick={onClick}>
                <Label as='a' pointing='right' color={colors[current]}>{getLocaleString(current)}</Label>
                <Button icon color={colors[pending]} compact size='mini'>
                    <div style={{minWidth: 18}}>&nbsp;</div>
                </Button>
            </Button>
        </Responsive>

    </>
        : <Button icon color={colors[current]} compact size='mini' onClick={onClick}>{getLocaleString(current)}</Button>
);

const tamperState = (state) => {
    const [current, pending] = state;

    const colors = {
        'Tamper': 'red',
        'On': 'green',
        'Off': 'grey',
        'Pending': 'orange'
    };

    return modeLabel(colors, current, pending);
};

const renderStolenState = (stolenState, id, setReportStolen, deviceFound, deviceClear, isGettingRecoveryCode) => {
    return (<div>
        {stolenState === 1 ? (
            <Dropdown
                style={{ backgroundColor: '#db2828', color: 'white', fontSize: '.85714286rem', fontWeight: 'bold', padding: 6, minWidth: '75px', textAlign: 'center', marginLeft: 2 }}
                text={isGettingRecoveryCode ? <Icon name="spinner" loading /> : 'Stolen'}
                direction='left'
                floating
                button
            >
                <Dropdown.Menu>
                    <Dropdown.Item text='Found' onClick={() => deviceFound(id)} />
                    <Dropdown.Item text='Cancel' onClick={() => deviceClear(id)} />
                </Dropdown.Menu>
            </Dropdown>
        ) : stolenState === 2 ? (
            <Dropdown
                style={{ backgroundColor: 'rgb(33,186,69)', color: 'white', fontSize: '.85714286rem', fontWeight: 'bold', padding: 6, minWidth: '75px', textAlign: 'center' }}
                text={isGettingRecoveryCode ? <Icon name="spinner" loading /> : 'Found'}
                direction='center'
                floating
                button
            >
                <Dropdown.Menu>
                    <Dropdown.Item text='Clear' onClick={() => deviceClear(id)} />
                </Dropdown.Menu>
            </Dropdown>
        ) : null}
    </div>);
}

const _operatingMode = (state, onClick, onExtendClick, deviceFound, deviceClear) => {
    const [current, pending, trackingStartTime, _id, stolenState] = state;

    const colors = {
        'Tracking': 'red',
        'Covert': 'black',
        'Normal': 'green',
        'Pending': 'orange'
    };

    return <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>{stolenState === 0 && (current === 'Tracking' ? locateExtender(state, onClick, onExtendClick) : modeLabel(colors, current, pending, onClick))} {renderStolenState(stolenState, _id,null,deviceFound,deviceClear)}</span>;
};

const locateExtender = (state, onClick, onExtendClick) => {
    const [current, pending, trackingStartTime] = state;
    // return <Button icon color={colors[current]} compact size='mini' onClick={onClick}>{getLocaleString(current)}?</Button>
    const getTimer = (startTime) => {
        const oneHourAhead = new Date(new Date(trackingStartTime).getTime() + 60 * 60 * 1000);
        const formattedTime = oneHourAhead.toISOString().substring(11, 16);
        const timer = [formattedTime];
    }

    const timer = trackingStartTime ? getTimer(trackingStartTime) : null;

    return <Button.Group size='mini'>
        <Button icon style={{padding: 6.482}} color='black' onClick={onExtendClick}><Icon name='plus' /></Button>
        <Button style={{padding: 6.482}} color='red' onClick={onClick}>{getLocaleString(current)}</Button>
        <Button style={{padding: 6.482}} color='black'>{pending === 'Tracking' ? <Icon name='angle double right' /> : pending && pending !== 'Tracking' ? <Icon name='close right' /> : timer ? <Countdown times={timer} /> : null}</Button>
    </Button.Group>
}

const getLastWakeup = lastWakeups => (_id) => {
    if (lastWakeups.length === 0 || lastWakeups.connect === false) return null;
    const styles = {};

    const lastWakeup = lastWakeups.find(lastWakeup => lastWakeup._id === _id);
    const days = lastWakeup ? Moment().diff(Moment(lastWakeup.date), 'days') : 0;

    if (days >= 2) {
        styles.color = 'orange';
        styles.fontWeight = 'bold';
    }

    if (days >= 3) {
        styles.color = 'red';
        styles.fontWeight = 'bold';
    }

    return lastWakeup && lastWakeup.date ? <span style={{...styles}}>{Moment(lastWakeup.date).fromNow()}</span> : null;
};

const wakeupLabel = (text, colour) => <Label as='a' color={colour} style={{padding: 6, fontWeight: 'bold'}}><span style={{fontWeight: 'bold'}}>{text}</span></Label>

const formatLastWakeup = date => {
    if (!date) return null;
    const styles = {};
    let lastWakeup;

    const days = date ? Moment().diff(Moment(date), 'days') : 0;

    if(days < 2 && days >= 0) {
        lastWakeup = <span style={{...styles}}>{Moment(date).fromNow()}</span>
    }

    if (days >= 2) {
        lastWakeup = wakeupLabel(Moment(date).fromNow(), 'orange');
    }

    if (days >= 3) {
        lastWakeup = wakeupLabel(Moment(date).fromNow(), 'red');
    }

    return lastWakeup;
};

const truncate = (text) => {
    return text && text.length > 10 ? <Popup
        inverted
        trigger={<span>{text.substr(0, 10) + '...'}</span>}
        content={text}
        position='bottom left'
    /> : text
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups,
        devices: state.devices,
        alerts: state.alerts,
        tags: state.tags,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        devicesGet: (payload) => dispatch(devicesGet(payload)),
        lastWakeupsGet: () => dispatch(lastWakeupsGet()),
        groupsGet: (payload) => dispatch(groupsGet(payload)),
        groupCreate: (payload) => dispatch(groupCreate(payload)),
        deviceAdd: (payload) => dispatch(deviceAdd(payload)),
        deviceRemove: (payload) => dispatch(deviceRemove(payload)),
        deviceUpdate: (payload) => dispatch(deviceUpdate(payload)),
        deviceGroupUpdate: (payload) => dispatch(deviceGroupUpdate(payload)),
        deviceSetTamper: (payload) => dispatch(deviceSetTamper(payload)),
        deviceSetOperMode: (payload) => dispatch(deviceSetOperMode(payload)),
        deviceClearAlert: (payload) => dispatch(clearDeviceAlert(payload)),
        deviceSetFavourite: (payload) => dispatch(deviceSetFavourite(payload)),
        deviceSelectAll: (payload) => dispatch(deviceSelectAll(payload)),
        filterCount: () => dispatch(filterCount()),
        hibernate: (payload) => dispatch(wakeupDaysUpdate(payload)),
        alertGet: (payload) => dispatch(alertGet(payload)),
        tagsGet: () => dispatch(tagsGet()),
        tagAdd: (payload) => dispatch(tagAdd(payload)),
        tagRemove: (payload) => dispatch(tagRemove(payload)),
        tagCount: (payload) => dispatch(tagCount(payload)),
        deviceTagsUpdate: (payload) => dispatch(deviceTagsUpdate(payload)),
        resetActioned: () => dispatch(resetActioned()),
        clearDeviceErrors: () => dispatch(clearDeviceErrors()),
        deviceRecovery: (payload) => dispatch(deviceRecovery(payload)),
        groupsSetPersisted: (payload) => dispatch(setPersistedGroups(payload)),
        deviceClearRecovery: (payload) => dispatch(deviceClearRecovery(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevices);
